<template>
  <BaseListPage locale-section="pages.municipalities" route="municipality">
    <MunicipalitiesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "MunicipalitiesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    MunicipalitiesList: () => import("./MunicipalitiesList")
  }
};
</script>
